import { getDefaultWallets, RainbowKitProvider, } from '@rainbow-me/rainbowkit';
import { configureChains, createClient, WagmiConfig } from 'wagmi';
import { mainnet } from 'wagmi/chains';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'

export const { chains, provider } = configureChains(
    [mainnet],
    [jsonRpcProvider({
        rpc: (chain) => ({
            http: "https://nd-510-071-956.p2pify.com/a14e907611a0173def2f9a7a92845b15",
        }),
    })]
);

const { connectors } = getDefaultWallets({
    appName: 'W3 Connect',
    chains
});

export const wagmiClient = createClient({
    autoConnect: true,
    connectors,
    provider
})