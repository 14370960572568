import { ConnectButton } from '@rainbow-me/rainbowkit';
import { Fragment, useEffect, useState } from 'react'
import { useAccount } from 'wagmi'
import { useBalance } from 'wagmi'
import { useDisconnect } from 'wagmi'
import { watchAccount } from '@wagmi/core'

export const ConnectWalletButton = ({ }) => {
    const { address, isConnecting, isDisconnected } = useAccount()
    const [currentAddress, setCurrentAddress] = useState("");

    const unwatch = watchAccount((account) => console.log(account))

    async function init() {
        // setCurrentAddress(address);
        watchAccount((account) => {
            if (account.address != address && account.address != null) {
                window.location.reload();
            }
        })
    }

    useEffect(() => {
        init();

    }, [address])

    return (
        <div>
            <ConnectButton.Custom >
                {({
                    account,
                    chain,
                    openAccountModal,
                    openChainModal,
                    openConnectModal,
                    authenticationStatus,
                    mounted,
                }) => {
                    // Note: If your app doesn't use authentication, you
                    // can remove all 'authenticationStatus' checks
                    const ready = mounted && authenticationStatus !== 'loading';
                    const connected =
                        ready &&
                        account &&
                        chain &&
                        (!authenticationStatus ||
                            authenticationStatus === 'authenticated');

                    return (
                        <div
                            {...(!ready && {
                                'aria-hidden': true,
                                'style': {
                                    opacity: 0,
                                    pointerEvents: 'none',
                                    userSelect: 'none',
                                },
                            })}
                        >
                            {(() => {
                                if (!connected) {
                                    return (
                                        <button onClick={openConnectModal} type="button" className='mainButton'>
                                            Connect Wallet
                                        </button>
                                    );
                                }

                                if (chain.unsupported) {
                                    return (
                                        <button onClick={openChainModal} type="button" className='font-bold text-sm bg-[#F3A423] px-[15px] py-[10px] rounded-full'>
                                            Switch network
                                        </button>
                                    );
                                }

                                return (
                                    <div style={{ display: 'flex', gap: 0 }}>

                                        <p>Already Connected</p>
                                    </div>
                                );
                            })()}
                        </div>
                    );
                }}

            </ConnectButton.Custom>
        </div>
    );
};

