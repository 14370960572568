import React, { useState, useEffect, useContext } from "react";
import '@rainbow-me/rainbowkit/styles.css';
import { RainbowKitProvider, } from '@rainbow-me/rainbowkit';
import { WagmiConfig } from 'wagmi';
import { chains, wagmiClient } from "./1.resources/2.js/wallet/walletConfig";
import ConnectWallet from "./2.views/1.connectWallet/connectWallet";


const App = ({ }) => {

  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider chains={chains} >
        <ConnectWallet />
      </RainbowKitProvider>
    </WagmiConfig>

  );

}

export default App;

