import { ConnectWalletButton } from "./partials/button";


const ConnectWallet = ({ }) => {

    async function message() {
        window.ReactNativeWebView.postMessage("yoyklo");
    }

    return (
        <div>
            <p>Hello</p>
            <button onClick={() => window.location = "exp://192.168.29.82:19000/--/check"}>YoYo</button>
            <ConnectWalletButton />
        </div>

    );

}

export default ConnectWallet;



